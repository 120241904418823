import React from 'react'
import { Flex } from '../components'
import { Commandbar, Status, Hint, Link }  from '../components'
import { action } from '../class'
import { Button, FontIcon } from 'react-md'

const CustomWrapper = ({
  title             = null,
  className         = '',
  actions           = [],
  backTo            = null,
  hintMessage       = null,
  hintShowIcon      = true,
  hintIconClassName = null,
  hintMore          = null,
  showCommandbar    = true,
  commandbar        = {},
  styleCommandBar = {},
  rightCorner=null,
  ...props
}) => {
  const defaultActions = []

  return (
    <Flex
      className={`mpk-table-wrapper mpk-full full-height ${className}`}
      direction={Flex.properties.direction.COLUMN}
      fit
    >
      { showCommandbar && (
        <Commandbar
          title={title}
          className="flex-none"
          actions={[...actions, ...defaultActions]}
          backTo={backTo}
          styleCommandBar={styleCommandBar}
          {...commandbar}
          rightCorner={rightCorner}
        />
      )}
      <Flex 
        className="mpk-full full-width"
        fit
      > 
        <Flex
          className="mpk-full full-height mpk-animation slide-in"
          direction={Flex.properties.direction.COLUMN}
          fit
        >
          {hintMessage && (
            <div className="mpk-padding-N padding-all mpk-paper mpk-full full-width">
              <Hint
                message={hintMessage}
                showIcon={hintShowIcon}
                iconClassName={hintIconClassName}
                more={hintMore}
              />
            </div>
          )}
          <Flex 
            className="mpk-full full-width mpk-position position-relative mpk-scrollable"
            fit
          >
            {props.children}
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}

CustomWrapper.action = action
CustomWrapper.Status = Status
CustomWrapper.Link = Link

export default CustomWrapper
