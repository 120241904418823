import { makeAutoObservable } from "mobx";
import qs from "querystring";
import { createBrowserHistory, createHashHistory } from "history";
import { toast } from "../services";

class NavigationStore {
  router = null;
  browserHistory = createBrowserHistory();

  constructor() {
    makeAutoObservable(this);
  }

  setHistory(browserHistory) {
    this.browserHistory = browserHistory;
  }

  setRouter(router) {
    this.router = router;
  }
  redirectTo(path) {
    if (this.browserHistory) this.browserHistory.push(path);
    else
      console.error("Navigation Store", ":", "Browser History is not set yet");
  }
  back() {
    if (this.browserHistory) this.browserHistory.goBack();
    else
      console.error("Navigation Store", ":", "Browser History is not set yet");
  }
  errorRequest(err) {
    let { response, config } = err;
    if (response && response.status === 401) {
      sessionStorage.setItem(
        "redirectUri",
        window.encodeURIComponent(window.location.href)
      );
      let errorUri = `/error?${qs.stringify({
        status: response ? response.status : 503,
        statusText: response ? response.statusText : "Network Error",
        errorMessage: err.message || "Connection problem",
        // redirectUri: window.encodeURIComponent(window.location.href)
      })}`;

      if (this.browserHistory) this.browserHistory.push(errorUri);
    }
    // console.log(JSON.stringify(err), "ROR");

    toast.error(`${err.message}: ${config.url}` || "Connection problem");
  }
}

export default NavigationStore;
