import React, { useEffect, useState } from "react";
import { CustomWrapper } from "../../libs/react-mpk/wrapper";
import t from "counterpart";
import "./Dashboard.scss";
import {
  Card,
  CardContent,
  CardTitle,
  Dialog,
  useToggle,
  Button,
  CircularProgress,
} from "react-md";
import { inject, observer } from "mobx-react";
import LoadingOverlay from "react-loading-overlay";
import { toastError } from "../../libs/react-mpk/services/sweetToast.service";
import service from "./Dashboard.service";
import { format } from "../../libs/react-mpk/services/number.service";
import stampingService from "../Report/Stamping/Stamping.service";
import Chart from "../../components/Chart";
import { DataForm } from "../../libs/react-mpk/components";
import { inputTypes } from "../../libs/react-mpk/config/constant";

const Dashboard = ({ authStore }) => {
  const [quotaEmet, setQuotaEmet] = useState(0);
  const [totalTopup, setTotalTopup] = useState(0);
  const [totalStamping, setTotalStamping] = useState(0);
  const [dataChart, setDataChart] = useState([]);
  const [filter, setFilter] = useState({});
  const [visible, enable, disable] = useToggle(false);

  const [loadingSaldoKuota, setLoadingSaldoKuota] = useState(true);
  const [loadingTotalTopup, setLoadingTotalTopup] = useState(true);
  const [loadingStamping, setLoadingStamping] = useState(true);
  const [loadingUsageOrder, setLoadingUsageOrder] = useState(true);

  const fetchSaldoKuota = async () => {
    setLoadingSaldoKuota(true);
    try {
      let res = await service.getSaldo();
      setQuotaEmet(res.data);
      setLoadingSaldoKuota(false);
    } catch (e) {
      // toastError("error", e);
      setLoadingSaldoKuota(false);
    }
  };

  const fetchTotalTopup = async () => {
    setLoadingTotalTopup(true);
    try {
      let res = await service.getTotalTopup({ "status.equals": "PAID" });
      setTotalTopup(res.data);
      setLoadingTotalTopup(false);
    } catch (e) {
      // toastError("error", e);
      setLoadingTotalTopup(false);
    }
  };

  const fetchStamping = async () => {
    setLoadingStamping(true);
    try {
      let res = await stampingService.getStamping({
        "status.equals": "COMPLETED",
      });
      console.log(res, "res");
      // console.log(res.headers["x-total-count"], 'res.headers["x-total-count"]');
      setTotalStamping(res.headers["x-total-count"]);
      setLoadingStamping(false);
    } catch (e) {
      // toastError("error", e);
      setLoadingStamping(false);
    }
  };

  const fetchUsageOrder = async (params) => {
    setLoadingUsageOrder(true);
    // try {
    //   let res = await service.UsageOrder(params);
    //   setDataChart(res.data);
    //   setLoadingUsageOrder(false);
    // } catch (e) {
    //   toastError("error", e);
    //   setLoadingUsageOrder(false);
    // }
  };

  const fetchAll = () => {
    fetchSaldoKuota();
    fetchTotalTopup();
    fetchStamping();
    fetchUsageOrder();
  };
  useEffect(() => {
    fetchAll();
  }, []);

  return (
    <LoadingOverlay spinner text="Loading your content...">
      <CustomWrapper
        baseId="mod-dashboard-id"
        title="Dashboard"
        className="mpk-full full-height"
        styleCommandBar={{
          backgroundColor: "white",
          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        }}
        actions={[
          {
            label: t.translate("mpk.column.reload"),
            iconClassName: "mdi mdi-reload",
            onClick: () => {
              fetchAll();
            },
          },
          {
            label: `${t.translate("mpk.column.filter")} ${t.translate(
              "word.orderUsage"
            )}`,
            iconClassName: "mdi mdi-filter-outline",
            onClick: () => enable(),
          },
        ]}
        children={
          <div className="column">
            <div className="row">
              <Card className="cardSaldo">
                <CardTitle className="card-title">
                  {t.translate("word.quotaEmet")}
                </CardTitle>
                <CardContent className="card-content">
                  {loadingSaldoKuota ? (
                    <CircularProgress centered={false} small={true} />
                  ) : (
                    format(quotaEmet.saldo)
                  )}
                </CardContent>
              </Card>
              <div style={{ padding: "0 10px 0 10px" }} />
              <Card className="cardSaldo">
                <CardTitle className="card-title">
                  {t.translate("word.totalTopup")}
                </CardTitle>
                <CardContent className="card-content">
                  {loadingTotalTopup ? (
                    <CircularProgress centered={false} small={true} />
                  ) : (
                    format(totalTopup.total)
                  )}
                </CardContent>
              </Card>
              <div style={{ padding: "0 10px 0 10px" }} />
              <Card className="cardSaldo">
                <CardTitle className="card-title">
                  {t.translate("word.totalStamping")}
                </CardTitle>
                <CardContent className="card-content">
                  {loadingStamping ? (
                    <CircularProgress centered={false} small={true} />
                  ) : (
                    format(totalStamping)
                  )}
                </CardContent>
              </Card>
            </div>
            {/* <div className="row">
              <Card
                fullWidth
                style={{
                  display: "flex",
                  flex: 1,
                  flexDirection: "column",
                  padding: "20px",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {loadingUsageOrder ? (
                  <CircularProgress centered={false} small={true} />
                ) : (
                  <Chart
                    title={t.translate("word.orderUsage")}
                    data={dataChart}
                  />
                )}
              </Card>
            </div> */}
          </div>
        }
      />
      <Dialog
        id="simple-dialog"
        visible={visible}
        onRequestClose={disable}
        aria-labelledby="dialog-title"
      >
        <h2
          style={{
            paddingLeft: "20px",
          }}
        >
          {`${t.translate("mpk.column.filter")} ${t.translate(
            "word.orderUsage"
          )}
        `}
        </h2>
        <DataForm
          baseId={`orderUsage-search`}
          defaultData={filter}
          submitIconClassName="mdi mdi-magnify"
          submitLabel={`${t.translate("word.search")}`}
          additionalAction={[
            {
              show: true,
              render: () => (
                <Button
                  style={{ marginRight: 15 }}
                  onClick={() => {
                    setFilter({});
                    fetchUsageOrder();
                    disable();
                  }}
                >
                  {t.translate("word.delete")}
                </Button>
              ),
            },
          ]}
          onSubmit={(values, callback) => {
            try {
              setFilter(values);
              fetchUsageOrder(values);
              callback("", false, false);
              disable();
            } catch (error) {}
          }}
          definitions={[
            {
              inputType: inputTypes.DATEPICKER,
              label: t.translate("word.startDate"),
              key: "startDate",
              type: "text",
            },
            {
              inputType: inputTypes.DATEPICKER,
              label: t.translate("word.endDate"),
              key: "endDate",
              type: "text",
            },
          ]}
        />
      </Dialog>
    </LoadingOverlay>
  );
};

export default inject("authStore")(observer(Dashboard));
