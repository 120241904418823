import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import logoSuccess from "../assets/Success.png";
import logoError from "../assets/Error.png";
import logoReminder from "../assets/Notif.png";

export const toastSuccess = async (title,message) => {
  Swal.fire({
    toast: true,
    position: "bottom-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    background: "#F2FFFC",
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
    html: `
    <div style="display:flex; flex-direction:row">
    <div style="display:flex; flex-direction:column">
    <img src="${logoSuccess}" alt="Custom Icon" style="width: 50px; height: 50px;">
    </div>
    <div style="display:flex; flex-direction:column; margin-left:20px">
    <span style="color:#4AAF57;font-weight:700">${title || 'Error'}</span>
    <span>${message}</span>
    </div>
      
      <button class="close-button" style="position: absolute;  right: 0; background: none; border: none; outline: none; color: inherit; cursor: pointer;font-size:20pt;margin-right:15px; margin-top:10px">&times;</button>
      </div>
  `,
  });
  const closeButton = document.querySelector(".close-button");
  closeButton.addEventListener("click", () => {
    Swal.close();
  });
};
export const toastError = async (title,message) => {
  Swal.fire({
    toast: true,
    position: "bottom-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    background: "#FFF5F5",
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
    html: `
      <div style="display:flex; flex-direction:row">
      <div style="display:flex; flex-direction:column">
      <img src="${logoError}" alt="Custom Icon" style="width: 50px; height: 50px;">
      </div>
      <div style="display:flex; flex-direction:column; margin-left:20px">
      <span style="color:#F75555;font-weight:700">${title || 'Error'}</span>
      <span>${message}</span>
      </div>
        
        <button class="close-button" style="position: absolute;  right: 0; background: none; border: none; outline: none; color: inherit; cursor: pointer;font-size:20pt;margin-right:15px; margin-top:10px">&times;</button>
        </div>
    `,
  });
  const closeButton = document.querySelector(".close-button");
  closeButton.addEventListener("click", () => {
    Swal.close();
  });
};
export const toastReminder = async (title,message) => {
  Swal.fire({
    toast: true,
    position: "bottom-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    background: "#FFFEE0",
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
    html: `
      <div style="display:flex; flex-direction:row">
      <div style="display:flex; flex-direction:column">
      <img src="${logoReminder}" alt="Custom Icon" style="width: 50px; height: 50px;">
      </div>
      <div style="display:flex; flex-direction:column; margin-left:20px">
      <span style="color:#F6A56E;font-weight:700">${title || 'Error'}</span>
      <span>${message}</span>
      </div>
        
        <button class="close-button" style="position: absolute;  right: 0; background: none; border: none; outline: none; color: inherit; cursor: pointer;font-size:20pt;margin-right:15px; margin-top:10px">&times;</button>
        </div>
    `,
  });
  const closeButton = document.querySelector(".close-button");
  closeButton.addEventListener("click", () => {
    Swal.close();
  });
};
